import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';

const SocialWrapper = styled.div`
  line-height: 2;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`;

const Icon = styled.i`
  color: ${(props) => props.theme.lightShades} !important;
  font-size: 1rem;
`;

const query = graphql`
  query LayoutQuery {
    sanitySiteSettings {
      facebook
      twitter
      instagram
      pinterest
    }
  }
`;

const socialNetworks = ({ instagram, facebook, twitter, pinterest }) => {
  const items = [];
  if (instagram) {
    items.push({
      id: 1,
      name: 'Instagram',
      icon: 'fab fa-instagram',
      link: instagram,
    });
  }

  if (facebook) {
    items.push({
      id: 2,
      name: 'Facebook',
      icon: 'fab fa-facebook-square',
      link: facebook,
    });
  }

  if (twitter) {
    items.push({
      id: 3,
      name: 'Twitter',
      icon: 'fab fa-twitter',
      link: twitter,
    });
  }
  if (pinterest) {
    items.push({
      id: 4,
      name: 'Pinterest',
      icon: 'fab fa-pinterest',
      link: pinterest,
    });
  }

  return items;
};

const SocialIcons = () => (
  <StaticQuery
    query={query}
    render={(data) => {
      const social = data.sanitySiteSettings;
      if (!social) {
        return null;
      }
      return (
        <SocialWrapper className="mb-5 is-flex">
          {socialNetworks({
            instagram: social.instagram,
            facebook: social.facebook,
            twitter: social.twitter,
            pinterest: social.pinterest,
          }).map((item) => (
            <div key={item.id} className="mr-4">
              <a
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={item.name}
              >
                <Icon className={item.icon} />
              </a>
            </div>
          ))}
        </SocialWrapper>
      );
    }}
  />
);

export default SocialIcons;
