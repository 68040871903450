import React, { useRef } from 'react';
import styled from 'styled-components';

const ScrollWrapper = styled.div`
  position: relative;

  .centered {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 6rem;
    @media screen and (max-width: 768px) {
      top: 2rem;
    }
  }
`;

const ScrollButton = () => {
  const ref = useRef();
  const scroll = () =>
    ref && ref.current && ref.current.scrollIntoView({ behavior: 'smooth' });

  return (
    <ScrollWrapper className="is-clickable mt-5" onClick={scroll}>
      <svg
        className="centered"
        ref={ref}
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.8848 20.8834L19.9424 25.9556L24.9999 20.8834"
          stroke="white"
          strokeWidth="2"
        />
        <path
          d="M19.9436 25.8896L19.9436 14.0545"
          stroke="white"
          strokeWidth="2"
        />
        <path
          d="M20 38.8402C9.49876 38.8402 0.999999 30.3997 0.999999 20.0053C1 9.61088 9.49876 1.17041 20 1.17041C30.5012 1.17041 39 9.61088 39 20.0053C39 30.3997 30.5012 38.8402 20 38.8402Z"
          stroke="white"
          strokeWidth="2"
        />
      </svg>
    </ScrollWrapper>
  );
};

export default ScrollButton;
