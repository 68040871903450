import React from 'react';
import PropTypes from 'prop-types';

const Heading = ({ children, size, centered, className, dark }) => (
  <h1
    className={`is-family-secondary		has-text-weight-bold	text-${size}   ${
      dark ? 'has-text-black' : 'has-text-white'
    }  ${centered && 'has-text-centered'}
      ${className}`}
  >
    {children}
  </h1>
);

Heading.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['3xl', '2xl', 'base']),
};

Heading.defaultProps = {
  className: '',
  size: '3xl', // 72px by default
};

export default Heading;
