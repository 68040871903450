import React from 'react';
import styled from 'styled-components';

const Input = styled.input``;

const InputGroup = ({ errors, children, maxLength, ...props }) => (
  <div className="field">
    <div className="control">
      {children || (
        <Input
          className={`input has-text-black is-size-8
           ${errors ? 'is-danger' : ''} `}
          maxLength={maxLength}
          {...props}
        />
      )}
      {errors && <p className="help is-danger">{errors}</p>}
    </div>
  </div>
);

export default InputGroup;
