import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';

import { Heading, Subtitle } from '../elements';
import SocialIcons from './SocialIcons';

const Section = styled.footer`
  background-color: ${(props) => props.theme.darkAccent} !important;
  .has-opacity {
    opacity: 0.7;
  }
`;

const BottomWrapper = styled.div`
  border-top: 1px solid ${(props) => props.theme.lightShades} !important;
`;

const footerQuery = graphql`
  {
    sanityFooter {
      _id
      footerLinks {
        _key
        label
        page {
          _id
          title
          slug {
            current
          }
        }
      }
    }
    sanitySiteSettings {
      title
    }
  }
`;

const Footer = () => {
  const { sanityFooter: data, sanitySiteSettings } =
    useStaticQuery(footerQuery);
  if (!data) {
    return null;
  }

  return (
    <Section className="section pb-2">
      <div className="container">
        <div className="columns">
          <div className="column is-4 is-hidden-mobile">
            {sanitySiteSettings.title && (
              <Link to="/">
                <Heading>{sanitySiteSettings.title}</Heading>
              </Link>
            )}
          </div>
          <div className="column is-2 is-hidden-mobile" />

          {data.footerLinks &&
            data.footerLinks.map((element) => {
              if (!element) return null;
              return (
                <div
                  className="column is-3 has-text-right-desktop has-text-centered-mobile"
                  key={element._key}
                >
                  <Heading className="is-size-2 mb-5">{element.label}</Heading>
                  <ul>
                    {element.page &&
                      element.page.map((item) => {
                        if (!item) return null;
                        const slug = item.slug && item.slug.current;
                        return (
                          <li className="mb-4" key={item._id}>
                            <Link
                              to={slug === 'homepage' ? '/' : `/${slug}`}
                              className="has-text-white has-opacity"
                            >
                              {slug === 'homepage' ? 'Home' : item.title}
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              );
            })}
        </div>
        <SocialIcons />
        <BottomWrapper className="columns is-vcentered has-text-centered-mobile">
          <div className="column">
            <Subtitle>
              ©{sanitySiteSettings.title || 'Bizzy buddies'}{' '}
              {new Date().getFullYear()}
            </Subtitle>
          </div>
          <div className="column">
            <Link to="/policy">
              <Subtitle className="has-text-right-desktop">
                privacy policy
              </Subtitle>
            </Link>
          </div>

          {/*  for mobile */}
        </BottomWrapper>
        <Link className="is-hidden-desktop has-text-centered" to="/">
          <Heading size="3xl">
            {sanitySiteSettings.title || 'Bizzy buddies'}
          </Heading>
        </Link>
      </div>
    </Section>
  );
};

export default Footer;
