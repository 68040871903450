import React from 'react';
import styled from 'styled-components';

const ButtonStyle = styled.button`
  background: ${(props) => props.theme.mainBrandColor};
  color: ${(props) => (props.secondary ? props.theme.mainBrandColor : '')};
  width: 15px !important;
`;

const SliderArrow = ({ children, className, ...props }) => (
  <ButtonStyle
    type="button"
    className={`button is-size-6 is-small has-text-weight-semibold is-rounded ${className} `}
    {...props}
  >
    {children}
  </ButtonStyle>
);

SliderArrow.defaultProps = {
  className: '',
};

export default SliderArrow;
