import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import config from '../../utils/config';

const Container = styled.div`
  position: fixed;
  bottom: 0rem;
  left: 0;
  right: 0;
  border-radius: 0 !important;
`;

const CookiesPopUp = () => {
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    if (window.localStorage.getItem('cookieAccepted') === null) {
      setDisplay(true);
    }
  }, []);

  const setCookie = () => {
    window.localStorage.setItem('cookieAccepted', true);
    setDisplay(!display);
  };

  return (
    <div>
      {display && (
        <Container
          className={`${
            display ? 'notification' : ''
          } has-background-black has-text-white`}
        >
          <div className="container">
            <div className="columns is-vcentered">
              <div className="column is-10">
                <p className="has-text-white">
                  By using this site, you agree to this {config.siteName} using
                  cookies.
                </p>
              </div>
              <div className="column has-text-right-desktop">
                <button
                  type="button"
                  onClick={() => setCookie()}
                  className="button is-rounded is-primary px-5"
                >
                  I Agree
                </button>
              </div>
            </div>
          </div>
        </Container>
      )}
    </div>
  );
};

export default CookiesPopUp;
