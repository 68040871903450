import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Heading from './Heading';
import Markdown from './Markdown';

import SanityImage from '../global/SanityImage';

const ContentWrapper = styled.div`
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : '')};
  p {
    color: ${(props) => props.theme.textGreyDark} !important;
    line-height: 30px;
  }
  .box {
    background: ${(props) => props.theme.lightCreamAccent};
    border-radius: 0;
  }
  .button {
    background: transparent;
  }
`;

const IconWrapper = styled.span`
  border: 2px solid red;
  padding: 1.3rem;
  border-radius: 2rem;
  transition: box-shadow 0.5s ease;
  :hover {
    box-shadow: ${(props) => props.theme.dangerShadow};
  }
`;

const OfferCard = ({ data, reverse }) => {
  if (!data) {
    return null;
  }
  return (
    <ContentWrapper className="columns is-gapless mb-6" reverse={reverse}>
      <div className="column hero">
        <div className="box hero-body is-flex is-align-items-center">
          <div>
            <h4 className="mb-5 has-text-weight-medium has-text-grey-dark">
              {data.title}
            </h4>
            <Heading size="3xl" dark>
              {data.subtitle}
            </Heading>
            <div className="content">
              <Markdown description={data.description} />
            </div>

            {data.slug && data.slug.current && (
              <Link
                to={`/offer/${data.slug.current}`}
                className="button has-text-weight-semibold pl-0 is-borderless"
              >
                <span>{data.buttonText || 'View Sessions'}</span>
                <IconWrapper className="icon is-small ml-3 has-text-danger">
                  <i className="fas fa-arrow-right" />
                </IconWrapper>
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className={`column ${reverse ? 'has-text-right' : 'has-text-left'}`}>
        {data.image.asset && (
          <SanityImage
            className="hero"
            fluid={data.image.asset.gatsbyImageData}
            alt="About us"
          />
        )}
      </div>
    </ContentWrapper>
  );
};

export default OfferCard;
