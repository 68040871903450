import React from 'react';
import PropTypes from 'prop-types';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import config from '../../utils/config';

const SanityImage = ({ fluid, alt, gatsbyImageId, width, ...props }) => {
  if (fluid) {
    const image = getImage(fluid);
    return (
      <GatsbyImage image={image} alt={alt || 'bizzy buddies'} {...props} />
    );
  }

  if (gatsbyImageId && width) {
    const sanityConfig = {
      projectId: config.sanityId,
      dataset: config.dataset,
    };

    const imageData = getGatsbyImageData(
      gatsbyImageId,
      { width },
      sanityConfig,
    );

    return <GatsbyImage image={imageData} alt={alt} width={width} />;
  }

  return null;
};

export default SanityImage;

SanityImage.propTypes = {
  alt: PropTypes.string,
  gatsbyImageId: PropTypes.string,
  width: PropTypes.number,
};

SanityImage.defaultProps = {
  alt: '',
  gatsbyImageId: '',
  width: 1400,
};
