import React from 'react';

import Heading from './Heading';
import Markdown from './Markdown';

const Content = ({ title, description, dark, left }) => {
  return (
    <div className={` ${left ? 'has-text-left' : 'has-text-centered'}`}>
      <Heading className={`mb-3 ${dark && 'has-text-black'}`}>{title}</Heading>
      {description && (
        <Markdown
          className={`has-text-weight-medium has-text-white ${
            dark && 'has-text-grey-dark'
          } `}
          description={description}
        />
      )}
    </div>
  );
};

export default Content;
