import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import SanityImage from '../global/SanityImage';
import Heading from './Heading';
import Markdown from './Markdown';

const ContentWrapper = styled.div`
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : '')};
  p {
    color: ${(props) => props.theme.textGreyDark} !important;
    line-height: 30px;
  }
  .button {
    background: transparent !important;
  }
`;

const IconWrapper = styled.span`
  border: 2px solid red;
  padding: 1.3rem;
  border-radius: 2rem;
`;

const Card = ({ data, reverse }) => (
  <ContentWrapper
    className="columns is-vcentered is-variable is-8"
    reverse={reverse}
  >
    <div className="column">
      <h2 className="mb-5 has-text-weight-medium has-text-grey-dark">
        {data.title}
      </h2>
      <Heading size="3xl" dark>
        {data.subtitle}
      </Heading>
      <div className="content">
        <Markdown description={data.description} />
      </div>

      {data.slug && data.slug.current && (
        <Link
          to={data.slug.current}
          className="button has-text-weight-semibold pl-0 is-borderless"
        >
          <span>View Birthday Parties</span>
          <IconWrapper className="icon is-small ml-3 has-text-danger">
            <i className="fas fa-arrow-right" />
          </IconWrapper>
        </Link>
      )}
    </div>
    <div className="column">
      {data.image && data.image.asset && (
        <SanityImage
          fluid={data.image.asset.gatsbyImageData}
          alt={data.title || 'bizzy buddies'}
        />
      )}
    </div>
  </ContentWrapper>
);

export default Card;
