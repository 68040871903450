import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset-advanced';

const mainBrandColor = '#FF0000';
const lightShades = '#FFFFFF';
const darkAccent = '#111111';
const darkShades = '#3C3C3B';

export const theme = {
  // It can be liberally applied to your layout as its main identity.
  mainBrandColor,
  // Accent colors can be used to bring attention to design elements
  // by contrasting with the rest of the palette.
  lightCreamAccent: '#FDEFE0',
  // Use this color as the background for your dark-on-light designs,
  // or the text color of an inverted design.
  lightShades,
  // Another accent color to consider. Not all colors have to be used -
  // sometimes a simple color scheme works best.
  darkAccent,
  // Use as the text color for dark-on-light designs,
  // or as the background for inverted designs.
  darkShades,
  borderColor: lightShades,

  // Background colors
  backgroundColor: mainBrandColor,
  backgroundInputColor: lightShades,

  // Font sizes
  fontSize: '16px',
  fontSizeBase: '18px', // 'default <p> text-size'
  fontSizeSmall: '24px',
  fontSizeMedium: '34px', // is-size-2
  fontSize3xl: '40px', // global heading size

  // Text colors
  textColor: darkShades,
  textColorLite: lightShades,
  textGreyDarker: '#3C3C3B',
  textGreyDark: '#636362',
  textGreyLight: '#878786',
  textGreyLighter: '#B1B1B1',
  textWhiteBis: '#D8D8D8',

  dangerColor: '#f44336',
  humBurgerColor: '#cf1717',

  // FONTS
  primaryFontFamily: "'BlinkMacSystemFont', sans-serif",
  secondaryFontFamily: "'Poppins', sans-serif",

  // Box shadows
  boxShadow: 'rgba(0,0,0,0.08) 0px 7px 18px',
  dangerShadow: '0px 0px 12px 3px rgb(255, 0, 0)',
};

const GlobalStyle = createGlobalStyle`
  ${reset};

  body {
    font-family: ${theme.secondaryFontFamily};
    color: ${theme.textColor};
    font-size: ${theme.fontSize};
  }

  h1 {
    font-family: ${theme.primaryFontFamily};
  }

  .subtitle {
    color: ${theme.darkShades};
    line-height: 30px;
  }

  .has-text-grey-dark	{
    color: ${theme.textGreyDark} !important;
  }

  .has-background-danger-dark	{
    background: ${theme.mainBrandColor} !important;
  }

  .has-background-white-bis	{
    background: #D8D8D8 !important;
  }

  .button.is-primary {
    background-color: transparent;
    transition: background-color 0.2s ease;
    border: 2px solid ${theme.borderColor};
    transition: background-color 0.4s ease;
    :hover {
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
      background-color: ${theme.darkAccent};
      border: 2px solid ${theme.borderColor};
      color: ${theme.textColorLite};
    }
  }
  .has-background-light {
    background-color: ${theme.textWhiteBis};
  }
  .button.is-dark {
    background-color: ${theme.darkAccent} !important;
  }

  .button.is-secondary {
    background-color: ${theme.darkAccent} !important;
    border: 2px solid ${theme.textGreyDarker};
    transition: background-color 0.4s ease;
    :hover {
      background-color: transparent !important;
      border: 2px solid ${theme.textGreyDarker};
      color: ${theme.textGreyDarker};
    }
  }

  .text-3xl {
    font-size: ${theme.fontSize3xl} !important;
    @media screen and (max-width: 768px) {
      font-size: ${theme.fontSizeMedium} !important;
    }
  }
  .text-2xl {
    font-size: ${theme.fontSizeSmall};
    @media screen and (max-width: 768px) {
      font-size: ${theme.fontSizeBase};
      line-height: 1.8 !important;
    }
  }
  .text-base {
    font-size: ${theme.fontSizeBase};
  }
  .text-small {
    font-size: ${theme.fontSize};
  }


  .leading-9 {
    line-height: 40px;
  }

  .is-borderless {
    border: none;
  }

  .content {
    strong {
      font-weight: bold !important;
    }
    p {
      font-size: ${theme.fontSizeBase} !important;
    }
  }

  .slick-slide div {
    outline: none;
  }
  .slick-prev:before,
  .slick-next:before {
    color: ${(props) => props.theme.mainBrandColor};
    font-size: 1.5rem;
  }
  .slick-dots {
    li button:before,
    li.slick-active button:before {
      color: transparent;
      opacity: 1;
    }
    li button:before {
      border: 2px solid ${(props) => props.theme.mainBrandColor};
      border-radius: 50%;
      height: 14px;
      width: 14px;
    }
    li.slick-active button:before {
      background-color: ${(props) => props.theme.mainBrandColor};
      color: transparent;
    }
  }
`;

export default GlobalStyle;
