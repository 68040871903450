import React from 'react';

const EmptyState = ({ text }) => (
  <div className="columns is-centered has-background-light py-6">
    <div className="column is-5 has-text-centered">
      <p className="is-6 subtitle has-text-grey">
        {text || 'No data available!'}
      </p>
    </div>
  </div>
);

export default EmptyState;
