import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const TextStyle = styled.p`
  line-height: 30px;
`;

const Subtitle = ({ children, size, centered, isBlack, className }) => (
  <TextStyle
    className={`text-${size} ${isBlack ? 'has-text-black' : 'has-text-white'}
      ${className} ${centered && 'has-text-centered'} `}
  >
    {children}
  </TextStyle>
);

Subtitle.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['3xl', '2xl', 'base', 'small']),
};

Subtitle.defaultProps = {
  className: '',
  size: 'base',
};

export default Subtitle;
