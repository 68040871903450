import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ButtonStyle = styled.button`
  background: ${(props) => (props.secondary ? props.theme.mainBrandColor : '')};
  color: ${(props) => (props.secondary ? props.theme.mainBrandColor : '')};
`;

const Button = ({ children, secondary, className, roundedNone, ...props }) => (
  <ButtonStyle
    secondary={secondary}
    className={`button is-medium has-text-weight-semibold ${className} ${
      roundedNone ? '' : 'is-rounded'
    } ${secondary ? 'is-secondary' : 'is-primary'}`}
    {...props}
  >
    {children}
  </ButtonStyle>
);

Button.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Button.defaultProps = {
  className: '',
};

export default Button;
