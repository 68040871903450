import React from 'react';
import styled from 'styled-components';

const TextArea = styled.textarea``;

const TextAreaGroup = ({ errors, ...props }) => (
  <div className="field">
    <div className="control">
      <TextArea className="textarea" {...props} />
      {errors && <p className="help is-danger">{errors}</p>}
    </div>
  </div>
);

export default TextAreaGroup;
