module.exports = {
  DEBUG: process.env.NODE_ENV === 'development',

  siteName: 'Bizzy Buddies',
  author: 'zeiq.co',
  description:
    'Bizzy BuddiesFun based sports coaching, providing a pathway for children to fall in love with sport',
  siteUrl: 'https://www.bizzy-buddies.co.uk',
  logo: '/images/logo.svg',
  metaLogo: '/images/logo.svg',
  telephone: '9876543210',
  email: 'bizzy-buddies.co.uk',
  address: 'Uk,London',
  twitter: 'https://twitter.com/bizzyltd',
  facebook: 'https://www.facebook.com/bizzybuddiesltd/',
  instagram: 'https://www.instagram.com/bizzybuddiesltd/?hl=en',

  type: 'website',
  googleAnalytics: 'U-1',
  backgroundColor: '#FFFFFF',
  themeColor: '#FF0000',
  sanityId: '5j7ccb54',
  dataset: 'production',
  mailchimp: '',
  apiUrl: 'https://bizzy-buddies-api.vercel.app/api/graphql',
};
