import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

const MarkdownStyled = styled(ReactMarkdown)`
  line-height: 30px;
`;

const Markdown = ({ description, className }) => (
  <MarkdownStyled className={`${className} content`} source={description} />
);

Markdown.propTypes = {
  description: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Markdown.defaultProps = {
  className: '',
};

export default Markdown;
